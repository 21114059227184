// import GitHubIcon from '@mui/icons-material/GitHub'
// import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import HomeIcon from '@mui/icons-material/Home';
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  // const { name, role, description, resume, social } = about
  const { name, role, description, social } = about

  return (
    <div className='about'>
      {name && (
        <h1 className='no-pads'>
          <span className='semi-title no-pads'>You are on...</span><br />
          <span className='about__name truenobold'>{name}</span>
        </h1>
      )}

      {role && <h2 className='about__role no-pads'>{role}</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {/* {resume && (
          <a href={resume}>
            <span type='button' className='btn btn--outline'>
              Resume
            </span>
          </a>
        )} */}

        {social && (
          <>
            {/* {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <GitHubIcon />
              </a>
            )} 

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <LinkedInIcon />
              </a>
            )} */}

            {social.youtube && (
              <a
                href={social.youtube}
                aria-label='youtube'
                className='link link--icon'
                target='_blank'
                rel='noopener noreferrer'
              >
                <YouTubeIcon />
              </a>
            )}
          </>
        )}

        <p className='link'><a href="https://flashmike.com/"><HomeIcon /></a></p>
      </div>

      
    </div>
  )
}

export default About
