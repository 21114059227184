// import { cardActionAreaClasses } from "@mui/material"
// import { color } from "@mui/system"
// import { isClickableInput } from "@testing-library/user-event/dist/utils"

const header = {
  homepage: 'https://animation.flashmike.com',
  // title: '{ animation }',
}

const about = {
  name: 'animation.FlashMike',
  role: 'Animation & Motion Graphics',
  description:
    'Storyboards, intros, and presentations.',
  resume: 'https://flashmike.com',
  social: {
    // linkedin: 'https://www.linkedin.com/in/mike-mijaro-2a35a7239/',
    // github: 'https://github.com/flashmike',
    youtube: 'https://www.youtube.com/user/flashwebworks/videos',
  },
}

const motion = [
  {
    name: 'SME Souk',
    image: 'https://flashmike.com/contents/motion/smesouk.jpg',
    alt: 'AE',
    description:
      'SME Souk presentation for Rakbank.',
    stack: ['Animation', 'AfterEffects'],
    livePreview: 'https://youtu.be/SW7icCiQRSU',
  },
  {
    name: 'Deal Today',
    image: 'https://flashmike.com/contents/motion/deal2day.jpg',
    alt: 'Deal Today',
    description:
      'Silent presentation for Deal Today for video advertising campaign.',
    stack: ['Flash', 'Animation'],
    livePreview: 'https://youtu.be/Qs_AxkzU26w',
  },
  {
    name: 'iMouse',
    image: 'https://flashmike.com/contents/motion/imouse.jpg',
    alt: 'iMouse',
    description:
      'My award-winning 2004 COMDDAP Animation Challenge entry.',
    stack: ['Flash', 'Animation', 'Rotoscoping'],
    livePreview: 'https://youtu.be/y25xbuPuT-M',
  },
  {
    name: 'Black Ace',
    image: 'https://flashmike.com/contents/motion/blackace.jpg',
    alt: 'Black Ace',
    description:
      'Website intro for blackace.com.',
    stack: ['Flash', 'Animation', 'AfterEffects', '3D'],
    livePreview: 'https://youtu.be/9Dkk-w-EjIo',
  },
  {
    name: 'Learn to Blog',
    image: 'https://flashmike.com/contents/motion/learn2blog.jpg',
    alt: 'Learn to Blog',
    description:
      '"Learn How To Blog" extended video presentation version.',
    stack: ['Flash', 'Animation'],
    livePreview: 'https://youtu.be/EdQWvZcREhE',
  },
  {
    name: 'Whiteboard',
    image: 'https://flashmike.com/contents/motion/whiteboard.jpg',
    alt: 'Whiteboard',
    description:
      'Whiteboard animation concept.',
    stack: ['Flash', 'Animation'],
    livePreview: 'https://youtu.be/mud5jXlj6Ls',
  },
  {
    name: 'I Sick You',
    image: 'https://flashmike.com/contents/motion/isicku.jpg',
    alt: 'I Sick You',
    description:
      'Just played with ICQ sounds and came up with this story. Mikmik meets Ribbit D\' Frog in a sickening way, created in 2004.',
    stack: ['Flash', 'Animation', 'Storyboarding', 'Character Development'],
    livePreview: 'https://youtu.be/tqUT9CzyhVo',
  },
  {
    name: 'I was halfwired',
    image: 'https://flashmike.com/contents/motion/halfwired.jpg',
    alt: 'I was halfwired',
    description:
      'Flash intro I created to mirror my site\'s objective and feature my skills in web and animation, back when I was halfwired.com in 2004.',
    stack: ['Flash', 'Animation', 'Storyboarding', 'Character Development'],
    livePreview: 'https://youtu.be/6WYS_sXnpmc',
  },
  {
    name: 'Junkie Tree Friends',
    image: 'https://flashmike.com/contents/motion/jtf.jpg',
    alt: 'Junkie Tree Friends',
    description:
      'Parody of Happy Tree Friends I created back in 2004.',
    stack: ['Flash', 'Animation', 'Storyboarding', 'Character Development'],
    livePreview: 'https://youtu.be/owMkMpqpUCE',
  },
  {
    name: 'Hello?!',
    image: 'https://flashmike.com/contents/motion/hello.jpg',
    alt: 'Hello?!',
    description:
      'Visualizing a comedy .wav file and created this animation short. Originally posted at newgrounds.com back in 2005.',
    stack: ['Flash', 'Animation', 'Storyboarding', 'Character Development'],
    livePreview: 'https://youtu.be/hvcVrFhOGn0',
  },
  {
    name: 'Complete Horse Care',
    image: 'https://flashmike.com/contents/motion/horse_care.jpg',
    alt: 'Complete Horse Care',
    description:
      'Complete Horse Care marketing video.',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/Ot1Pc-0pnlE',
  },
  {
    name: 'LHTB Logo',
    image: 'https://flashmike.com/contents/motion/lhtb-logo.jpg',
    alt: 'LHTB Logo',
    description:
      '3D animation of LHTB logo for video intros.',
    stack: ['Flash', '3D Animation'],
    livePreview: 'https://youtu.be/HAtIQJy8hag',
  },
  {
    name: 'LHTB 1-min video',
    image: 'https://flashmike.com/contents/motion/lhtb1m.jpg',
    alt: 'LHTB 1-min video',
    description:
      '3D animation of LHTB logo for video intros.',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/MDBvfi3cdY8',
  },
  {
    name: 'Mastering Motel Management',
    image: 'https://flashmike.com/contents/motion/mmm.jpg',
    alt: 'Mastering Motel Management',
    description:
      'Marketing video for Mastering Motel Management (2012).',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/GDJVxQaq8Bc',
  },
  {
    name: 'Longevity Time',
    image: 'https://flashmike.com/contents/motion/longevity.jpg',
    alt: 'Whiteboard',
    description:
      'Marketing video created for Longevity Time.',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/TM9mHq_daI4',
  },
  {
    name: 'Cooking With Color',
    image: 'https://flashmike.com/contents/motion/cooking.jpg',
    alt: 'Cooking With Color',
    description:
      'Cooking With Color marketing video.',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/GS-F6YgsnRs',
  },
  {
    name: 'Launch Web Services',
    image: 'https://flashmike.com/contents/motion/lws.jpg',
    alt: 'Launch Web Services',
    description:
      'Marketing video for Launch Web Services (2012).',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/-rKpUxK54b8',
  },
  {
    name: 'iDecide',
    image: 'https://flashmike.com/contents/motion/idecide.jpg',
    alt: 'iDecide',
    description:
      'App UI/UX case study presented in Flash.',
    stack: ['Flash', 'Animation', 'Graphics'],
    livePreview: 'https://youtu.be/nl2VmhKeTQM',
  },

]

const projects = [
  {
    name: 'My Home',
    image: 'https://flashmike.com/contents/apps/myhome.jpg',
    alt: 'My Home',
    description:
      'Real Estate website',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/myhome',
  },
  {
    name: 'MaMiZiMi',
    image: 'https://flashmike.com/contents/apps/mamizimi.jpg',
    alt: 'MaMiZiMi Online Shop',
    description:
      'Amazon clone',
    stack: ['React', 'Firebase'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/amazon-clone/',
  },
  {
    name: 'Your Drum Shop',
    image: 'https://flashmike.com/contents/apps/your-drum-shop.jpg',
    alt: 'Your Drum Shop',
    description:
      'eCommerce product selector',
    stack: ['React', 'Redux', 'MUI'],
    sourceCode: '#',
    livePreview: 'https://flashmike.github.io/your-drum-shop/',
  },
  {
    name: 'iLugaw',
    image: 'https://flashmike.com/contents/apps/ilugaw.jpg',
    alt: 'iLugaw',
    description:
      'Online web and mobile app',
    stack: ['React', 'UI/UX', 'Android'],
    sourceCode: 'https://github.com/flashmike/react-ilugaw',
    livePreview: 'https://flashmike.github.io/react-ilugaw/',
  },
  {
    name: 'Booking System',
    //image: './assets/apps/booksys.jpg',
    image: 'https://flashmike.com/contents/apps/booksys.jpg',
    alt: 'Booking System',
    description:
      'Online Booking System',
    stack: ['React', 'Bootstrap', 'UI/UX'],
    sourceCode: '#',
    livePreview: 'https://flashmike.github.io/booking-v2/',
  },
  {
    name: 'One Nature',
    image: 'https://flashmike.com/contents/apps/onenature.jpg',
    alt: 'One Nature',
    description:
      'One Nature in React',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/onenature',
  },
  // {
  //   name: 'Movie Library',
  //   image: 'https://flashmike.com/contents/apps/movie-app.jpg',
  //   alt: 'Movie Library',
  //   description:
  //     'Movie title listing with "search" and "add to favorites"',
  //   stack: ['React', 'Bootstrap', 'JSX'],
  //   sourceCode: 'https://github.com/flashmike/movie-app',
  //   livePreview: 'https://flashmike.github.io/movie-app/',
  // },
  {
    name: 'My Reactified CV',
    image: 'https://flashmike.com/contents/apps/mmcv.jpg',
    alt: 'My Reactified CV',
    description:
      'Reactified CV',
    stack: ['React', 'Fontawesome'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/cv',
  },
  {
    name: 'Triconstruct',
    image: 'https://flashmike.com/contents/apps/triconstruct.jpg',
    alt: 'Triconstruct',
    description:
      'Triconstruct in React',
    stack: ['React', 'JS'],
    sourceCode: '#',
    livePreview: 'https://triconstruct.com',
  },
  // {
  //   name: 'Movie Library',
  //   image: 'https://flashmike.com/contents/apps/movie-app.jpg',
  //   alt: 'Movie Library',
  //   description:
  //     'Movie title listing with "search" and "add to favorites"',
  //   stack: ['React', 'Bootstrap', 'JSX'],
  //   sourceCode: 'https://github.com/flashmike/movie-app',
  //   livePreview: 'https://flashmike.github.io/movie-app/',
  // },
  // {
  //   name: 'Movie Library',
  //   image: 'https://flashmike.com/contents/apps/movie-app.jpg',
  //   alt: 'Movie Library',
  //   description:
  //     'Movie title listing with "search" and "add to favorites"',
  //   stack: ['React', 'Bootstrap', 'JSX'],
  //   sourceCode: 'https://github.com/flashmike/movie-app',
  //   livePreview: 'https://flashmike.github.io/movie-app/',
  // },
  // {
  //   name: 'Movie Library',
  //   image: 'https://flashmike.com/contents/apps/movie-app.jpg',
  //   alt: 'Movie Library',
  //   description:
  //     'Movie, series, and game titles library listing with "search" and "add to favorites"',
  //   stack: ['React', 'Bootstrap', 'JSX'],
  //   sourceCode: 'https://github.com/flashmike/movie-app',
  //   livePreview: 'https://flashmike.github.io/movie-app/',
  // },
]

const web = [
  {
    name: 'Kultura Trip',
    image: 'https://flashmike.com/contents/web/kultura_trip.jpg',
    alt: 'Kultura Trip',
    description:
      'e-Commerce shop - [currently building...]',
    stack: ['HTML5', 'Bootstrap'],
    livePreview: 'https://gigaflapp.com/KulturaTrip/',
  },
  {
    name: 'One Nature',
    image: 'https://flashmike.com/contents/apps/onenature.jpg',
    alt: 'One Nature',
    description:
      'One Nature in React',
    stack: ['React', 'Vite', 'JSX'],
    sourceCode: '#',
    livePreview: 'https://app.flashmike.com/onenature',
  },
  {
    name: 'Triconstruct',
    image: 'https://flashmike.com/contents/apps/triconstruct.jpg',
    alt: 'Triconstruct',
    description:
      'One Nature in React',
    stack: ['React', 'JS'],
    sourceCode: '#',
    livePreview: 'https://triconstruct.com',
  },
  {
    name: 'IKAD Engineering',
    image: 'https://flashmike.com/contents/web/ikad.jpg',
    alt: 'IKAD Engineering',
    stack: ['HTML5', 'Bootstrap', 'PHP'],
    livePreview: 'https://ikad.com.au/',
  },
  {
    name: 'Harvest Tours',
    image: 'https://flashmike.com/contents/web/harvesttours.jpg',
    alt: 'Harvest Tours',
    stack: ['WIX'],
    livePreview: 'https://www.harvesttours.com.au/',
  },
  // {
  //   name: 'One Nature Hotels',
  //   image: 'https://flashmike.com/contents/web/onenature.jpg',
  //   alt: 'One Nature Hotels',
  //   stack: ['HTML5', 'Bootstrap', 'PHP'],
  //   livePreview: 'https://onenaturehotels.com',
  // },
  {
    name: 'LD Records',
    image: 'https://flashmike.com/contents/web/ldr.jpg',
    alt: 'Lonesome Day Records',
    stack: ['Wordpress'],
    livePreview: 'https://www.lonesomeday.com/',
  },
]

const graphic = [
  {
    name: 'Logo',
    image: 'https://flashmike.com/contents/graphics/logo.jpg',
    alt: 'Logo',
    livePreview: 'https://flashmike.com/contents/graphics/logos/logo_eod.png',
  },
  {
    name: 'Header',
    image: 'https://flashmike.com/contents/graphics/header.jpg',
    alt: 'Header',
    livePreview: 'https://flashmike.com/graphics.html',
  },
  {
    name: 'Banner',
    image: 'https://flashmike.com/contents/graphics/banner.gif',
    alt: '300x250 Banner',
    livePreview: 'https://flashmike.com/contents/graphics/banners/300x250/6pack_300x250a.gif',
  },
  {
    name: 'Branding',
    image: 'https://flashmike.com/contents/graphics/branding.jpg',
    alt: 'Booking System',
    livePreview: 'https://flashmike.com/contents/graphics/branding/brand2_2bros.jpg',
  },
  {
    name: 'Layout',
    image: 'https://flashmike.com/contents/graphics/layout.jpg',
    alt: '300x250 Banner',
    livePreview: 'https://flashmike.com/graphics.html',
  },
  {
    name: 'Print',
    image: 'https://flashmike.com/contents/graphics/print.jpg',
    alt: 'Print',
    livePreview: 'https://flashmike.com/graphics.html',
  },
]

const skills = [
  // 'HTML',
  // 'CSS',
  // 'JavaScript',
  // 'TypeScript',
  // 'React',
  // 'Redux',
  // 'SASS',
  // 'Material UI',
  // 'Git',
  // 'CI/CD',
  // 'Jest',
  // 'Enzyme',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'mike@flashmike.com',
}

export { header, about, projects, web, motion, graphic, skills, contact }
