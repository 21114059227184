import { useContext, useState } from 'react'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { ThemeContext } from '../../contexts/theme'
// import { projects, web, uiux, motion, graphic, skills, contact } from '../../portfolio'
import { web, motion, contact } from '../../portfolio'
import './Navbar.css'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import HomeIcon from '@mui/icons-material/Home';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'

// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import About from '../About/About'

const Navbar = () => {
  const [{ themeName, toggleTheme }] = useContext(ThemeContext)
  const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = () => setShowNavList(!showNavList)

  return (
    <nav className='center nav'>
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >
        {web.length ? (
          <li className='nav__list-item'>
            <a
              href='https://flashmike.com'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <HomeIcon className='padtop' /> home
            </a>
          </li>
        ) : null}

        {/* {projects.length ? (
          <li className='nav__list-item'>
            <a
              href='#projects'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <AppsIcon className='padtop' /> app
            </a>
          </li>
        ) : null} */}

        {/* {mobile.length ? (
          <li className='nav__list-item'>
            <a
              href='#mobile'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <PhoneAndroidIcon className='padtop' /> mobile
            </a>
          </li>
        ) : null}

        {uiux.length ? (
          <li className='nav__list-item'>
            <a
              href='#uiux'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <DragIndicatorIcon className='padtop' /> uiux
            </a>
          </li>
        ) : null} */}

        {motion.length ? (
          <li className='nav__list-item'>
            <a
              href='#motion'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <PlayCircleFilledIcon className='padtop' /> motion
            </a>
          </li>
        ) : null}

        {/* {graphic.length ? (
          <li className='nav__list-item'>
            <a
              href='#graphic'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <VisibilityIcon className='padtop' /> graphic
            </a>
          </li>
        ) : null} */}

        {/* {skills.length ? (
          <li className='nav__list-item'>
            <a
              href='#skills'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Skills
            </a>
          </li>
        ) : null} */}

        {contact.email ? (
          <li className='nav__list-item'>
            <a
              href='mailto:mike@flashmike.com'
              onClick={toggleNavList}
              className='link link--nav'
            >
              <PermContactCalendarIcon className='padtop' />
            </a>
          </li>
        ) : null}
      </ul>

      {/* <a href='https://flashmike.com/web.html' aria-label='source code' target='_blank' rel='noopener noreferrer'>

        &#60; graphics &#62;

      </a> */}

      <button
        type='button'
        onClick={toggleTheme}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {themeName === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
      </button>

      <button
        type='button'
        onClick={toggleNavList}
        className='btn btn--icon nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <DoubleArrowIcon /> : <CalendarViewDayIcon />}
      </button>

      {/* <BrowserRouter>
        <Routes>
          <Route path="/motion-graphics" element={<About />} />
        </Routes>
      </BrowserRouter> */}
    </nav>
  )
}

export default Navbar
