import uniqid from 'uniqid'
import { motion } from '../../portfolio'
import MotionContainer from '../MotionContainer/MotionContainer'
import './Motion.css'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'

const Motion = () => {
  if (!motion.length) return null

  return (
    // <section id='motion' className='section motion'>
    <section id='motion' className='section'>
      <h3 className='section__title'><PlayCircleFilledIcon /> Motion Graphics</h3>

      <div className='motion__grid'>
        {motion.map((motion) => (
          <MotionContainer key={uniqid()} motion={motion} />
        ))}
      </div>

      {/* <div className='btn-container'>
        <a href='https://flashmike.com/animation.html' aria-label='source code' target='_blank' rel='noopener noreferrer'>
          <span type='button' className='btn btn--outline'>
            VIEW MORE
          </span>
        </a>
      </div> */}

    </section>
  )
}

export default Motion
