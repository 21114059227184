// created by Mike Flash | flashmike.com | mike@flashmike.com
import { useContext } from 'react'
import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import About from './components/About/About'
import Motion from './components/Motion/Motion'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Footer from './components/Footer/Footer'
import './App.css'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)

  // const root = createRoot(document.getElementById("root"));
  // root.render(
  //   <StrictMode>
  //     <App />
  //   </StrictMode>,
  // );

  return (
    <div id='top' className={`${themeName} app`}>

      <Header />

      <main>
        <About />
        <Motion />

        {/* <BrowserRouter>
          <Routes>
            <Route path="/motion-graphics" element={<About />} />
          </Routes>
        </BrowserRouter> */}
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  )
}

export default App
