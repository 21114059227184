import { render } from 'react-dom'
import App from './App'
import { ThemeProvider } from './contexts/theme'
import './index.css'
import './fonts/truenobd-webfont.ttf'
import './fonts/truenolt-webfont.ttf'
import './fonts/truenoblk-webfont.ttf'
import './fonts/truenobdit-webfont.ttf'
import './fonts/trueno-reg.otf'

render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)
