import uniqid from 'uniqid'
import YouTubeIcon from '@mui/icons-material/YouTube';
import './MotionContainer.css'

const MotionContainer = ({ motion }) => (
  <div className='motion'>
    {<a href={motion.livePreview} target='_blank' rel='noopener noreferrer' >
      <img src={motion.image} alt={motion.alt} width='90%'></img>
    </a>}

    <a
      href={motion.livePreview}
      aria-label='live preview'
      className='link'
      target='_blank'
      rel='noopener noreferrer'
    ><h4 className='p-b-20'>{motion.name}</h4></a>

    <p className='motion__description'>{motion.description}</p>

    {motion.stack && (
      <ul className='motion__stack'>
        {motion.stack.map((item) => (
          <li key={uniqid()} className='motion__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {motion.livePreview && (
      <a
        href={motion.livePreview}
        aria-label='live preview'
        className='link link--icon'
        target='_blank'
        rel='noopener noreferrer'
      >
        <YouTubeIcon />
      </a>
    )}
  </div>
)

export default MotionContainer
